/* global baseUrl, csrf */
function XHR() {}

XHR.requests = {};

/**
 * XHR Request
 *
 * @param url {String}
 * @param params {Object}
 * @param method {String}
 * @returns {Promise}
 * @private
 */
XHR._ajax = function (url, params, method) {
	return new Promise(function (resolve, reject) {
		var request,
			paramString = '',
			formData = new FormData(),
			actionString = '';

		if (params.hasOwnProperty('action'))
			actionString = params.action;

		if (params.hasOwnProperty('formData'))
			formData = params.formData;

		if (XHR.requests.hasOwnProperty(url + actionString)) {
			request = XHR.requests[url + actionString];
			request.abort();
		} else {
			request = new XMLHttpRequest();
			XHR.requests[url + actionString] = request;
		}

		if (!params) params = {};
		params[csrf[0]] = csrf[1];

		for (var key in params) {
			if (params.hasOwnProperty(key)) {
				if (key === 'formData') continue;
				paramString += key + '=' + encodeURI(params[key]) + '&';
				XHR._objectToFormData(formData, params, key, key);
			}
		}

		if (paramString[paramString.length - 1] === '&')
			paramString = paramString.slice(0, -1);

		switch (method) {
			case 'GET':
				request.open('GET', url + '?' + paramString, true);
				break;
			case 'POST':
				request.open('POST', url, true);
				break;
			default:
				reject(Error('Unknown request method: ' + method));
		}

		request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
		request.setRequestHeader("Accept", "application/json");

		request.onload = function () {
			var data = request.responseText;

			if (request.getResponseHeader("content-type").toLowerCase().indexOf('json') > -1)
				data = JSON.parse(data);

			if (request.status >= 200 && request.status < 400) {
				resolve(data);
			} else {
				reject(Error(request.statusText));
			}
		};

		request.onerror = function () {
			reject(Error("Network Error"));
		};

		request.send(method === 'POST' ? formData : null);
	});
};

XHR._objectToFormData = function (formData, obj, key, keyStack) {
	if (obj[key].constructor === Array || typeof obj[key] === typeof {}) {
		for (var k in obj[key]) {
			if (!obj[key].hasOwnProperty(k)) continue;
			XHR._objectToFormData(formData, obj[key], k, `${keyStack}[${k}]`);
		}
	} else {
		formData.append(keyStack, obj[key]);
	}
};

/**
 * GET Request
 *
 * @param url {String}
 * @param params {Object=}
 * @return {Promise}
 */
XHR.get = function (url, params) {
	if (url[0] !== '/' && url[1] !== '/') url = baseUrl + url;
	if (!params) params = {};
	params.cacheBreak = Date.now();
	return XHR._ajax(url, params, 'GET');
};

/**
 * POST Request
 *
 * @param url {String}
 * @param params {Object=}
 * @return {Promise}
 */
XHR.post = function (url, params) {
	if (url.length > 2 && url[0] !== '/' && url[1] !== '/') url = baseUrl + url;
	if (!params) params = {};
	return XHR._ajax(baseUrl + url, params, 'POST');
};

/**
 * POST Action Request
 *
 * @param action {String}
 * @param params {Object=}
 * @return {Promise}
 */
XHR.action = function (action, params) {
	if (!params) params = {};
	params.action = action;
	return XHR.post('', params);
};

export default XHR;