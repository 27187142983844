import mobile from "./app/mobile";
import message from "./app/message";
import forms from "./app/forms";
import search from "./app/search";
import slider from "./app/slider";
import gallery from "./app/gallery";

import index from "./app/index";
import events from "./app/events";
import Event from "./app/event";
import page from "./app/page";
import login from "./app/login";
import account from "./app/account";

import Cart from "./app/cart";
import Checkout from "./app/checkout";


window.onload = function () {
	if (window.isMobile) mobile();
	message.init();
	forms.init();
	search.init();
	slider.init();
	gallery.init();

	switch (window.p) {
		case 'index':
			index.init();
			break;
		case 'events':
			events.init();
			break;
		case 'event':
			new Event();
			break;
		case 'page':
			page.init();
			break;
		case 'campsite':
			page.init();
			window.campsite = true;
			break;

		case 'login':
			login.init();
			break;
		case 'account':
			account.init();
			break;

		case 'cart':
			new Cart();
			break;
		case 'checkout':
			new Checkout();
			break;
	}

	if (window.campsite) {
		import('./app/Campsite.jsx').then(({ default: Campsite }) => {
			new Campsite();
		}).catch(console.error); // eslint-disable-line no-console
	}

	// Ether Tag
	try {
		console.log.apply(console, [ // eslint-disable-line no-console
			"%c %c %c %c  Site by Ether  %c %c %c %c http://ethercreative.co.uk",
			"background: rgba(180, 214, 65, 0.3); padding-top: 2px; padding-bottom: 2px;",
			"background: rgba(180, 214, 65, 0.6); padding-top: 2px; padding-bottom: 2px;",
			"background: rgba(180, 214, 65, 0.8); padding-top: 2px; padding-bottom: 2px;",
			"color: #ffffff; background: #b4d641; padding-top: 2px; padding-bottom: 2px;",
			"background: rgba(180, 214, 65, 0.8); padding-top: 2px; padding-bottom: 2px;",
			"background: rgba(180, 214, 65, 0.6); padding-top: 2px; padding-bottom: 2px;",
			"background: rgba(180, 214, 65, 0.3); padding-top: 2px; padding-bottom: 2px;",
			"background: #ffffff; color: #777; padding-top: 2px; padding-bottom: 2px;"
		]);
	} catch (e) {} // eslint-disable-line no-empty
};