const gallery = {
	galleries: [],
	init: function () {
		this.galleries = document.getElementsByClassName('page--gallery');

		[].slice.call(this.galleries).forEach(this.setupGallery);
	},
	setupGallery: function (el) {
		var imgsCont = el.getElementsByClassName('imgs')[0],
			imgs = imgsCont.getElementsByTagName('img'),
			controlsCont = el.getElementsByClassName('controls')[0],
			wrap = el.parentNode;

		[].slice.call(imgs).forEach(function (img) {
			if (img.clientHeight > imgsCont.clientHeight)
				imgsCont.style.height = img.clientHeight + 'px';
		});

		wrap.style.height = (imgsCont.clientHeight + controlsCont.clientHeight) + 'px';

		el.style.width = el.clientWidth + 'px';
		el.style.height = el.clientHeight + 'px';

		function fsEnd () {
			el.classList.remove('trans');
			el.classList.remove('fullscreen');
			el.classList.remove('fullscreen-exit');
			document.body.classList.remove('no-scroll');
			el.style.top = '0px';
			el.style.left = '0px';
			el.style.position = 'relative';
			el.removeEventListener('transitionend', fsEnd);
		}

		function escFullscreen (e) {
			if (e.keyCode === 27)
				toggleFullscreen();
		}

		function fullscreenArrows (e) {
			if (e.keyCode === 39)
				changeActive(true);
			else if (e.keyCode === 37)
				changeActive();
		}

		function toggleFullscreen (e) {
			if (e) e.preventDefault();
			if (el.classList.contains('fullscreen')) {
				el.addEventListener('transitionend', fsEnd);
				el.classList.add('fullscreen-exit');
				document.removeEventListener('keydown', escFullscreen);
				document.removeEventListener('keydown', fullscreenArrows);
			} else {
				document.body.classList.add('no-scroll');

				el.style.top = el.getBoundingClientRect().top + 'px';
				el.style.left = el.getBoundingClientRect().left + 'px';
				el.style.position = 'fixed';

				setTimeout(function () {
					el.classList.add('trans');
					el.classList.add('fullscreen');
				}, 50);

				document.addEventListener('keydown', escFullscreen);
				document.addEventListener('keydown', fullscreenArrows);
			}
		}

		controlsCont.getElementsByClassName('fullscreen')[0].addEventListener('click', toggleFullscreen);

		function changeActive (goNext) {
			var currActive = imgsCont.getElementsByClassName('active')[0];
			currActive.classList.remove('active');

			if (goNext) {
				if (currActive.nextElementSibling)
					currActive.nextElementSibling.classList.add('active');
				else
					imgs[0].classList.add('active');
			} else {
				if (currActive.previousElementSibling)
					currActive.previousElementSibling.classList.add('active');
				else
					imgs[imgs.length - 1].classList.add('active');
			}
		}

		var xDown = null,
			yDown = null;

		el.addEventListener('touchstart', function (e) {
			xDown = e.touches[0].clientX;
			yDown = e.touches[0].clientY;
		}, false);

		el.addEventListener('touchmove', function (e) {
			if ( ! xDown || ! yDown )
				return;

			var xUp = e.touches[0].clientX;
			var yUp = e.touches[0].clientY;

			var xDiff = xDown - xUp;
			var yDiff = yDown - yUp;

			if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
				changeActive(xDiff > 0);
			}

			xDown = null;
			yDown = null;
		}, false);

		[].slice.call(controlsCont.getElementsByClassName('nav')).forEach(function (el) {
			el.addEventListener('click', function (e) {
				e.preventDefault();
				changeActive(el.classList.contains('next'));
			});
		});
	}
};

export default gallery;