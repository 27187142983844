/* global Stripe */
import message from "./message";
// import Addresses from "./addresses";

export default class Checkout {

	constructor () {
		this.next = document.getElementById('checkoutNext');

		switch (window.step) {
			case 1:
				this.loginStep();
				break;
			case 2:
				// new Addresses();
				break;
			case 4:
				// this.payment();
				break;
		}

		if (window.step !== 1)
			this.nextSubmit();
	}

	nextSubmit () {
		if (document.getElementById('checkoutBody') === null) return;
		const form = document.getElementById('checkoutBody').querySelector("form");

		this.next.addEventListener('click', (e) => {
			e.preventDefault();
			let fakeSubmit = document.createElement('button');
			fakeSubmit.type = 'submit';
			fakeSubmit.style = 'display:none';
			form.appendChild(fakeSubmit);
			fakeSubmit.click();
			form.removeChild(fakeSubmit);
		});
	}

	loginStep () {
		const loginElem = document.getElementById('checkoutLogin');

		const forms = [].slice.call(loginElem.children);
		let activeForm = 0;

		this.next.textContent = forms[0].dataset.btn;

		forms.forEach((form, i) => {
			form.addEventListener('click', () => {
				this.next.textContent = form.dataset.btn;
				activeForm = i;
			});
		});

		this.next.addEventListener('click', (e) => {
			e.preventDefault();
			let fakeSubmit = document.createElement('button');
			fakeSubmit.type = 'submit';
			fakeSubmit.style = 'display:none';
			forms[activeForm].appendChild(fakeSubmit);
			fakeSubmit.click();
			forms[activeForm].removeChild(fakeSubmit);
		});
	}

	payment () {
		const cardNumber = document.getElementById('cardNumber')
			, button = document.getElementById('checkoutNext');

		cardNumber.addEventListener('input', function () {
			this.value = this.value.replace(/ /g,'').replace(/\D/g,'').replace(/(.{4})/g, '$1 ').trim();
		});

		if (typeof Stripe !== typeof undefined) {
			var form = document.getElementById('paymentForm');

			function onSubmit(e) { // eslint-disable-line no-inner-declarations
				e.preventDefault();

				button.classList.add('working');

				Stripe.card.createToken(form, function (status, response) {
					if (response.error) {
						button.classList.remove('working');
						message.show(response.error.message, true);
					} else {
						var t = document.createElement('input');
						t.setAttribute('type', 'hidden');
						t.setAttribute('name', 'stripeToken');
						t.value = response.id;

						form.insertBefore(t, form.firstElementChild);
						form.removeEventListener('submit', onSubmit);

						form.submit();
					}
				});

				return false;
			}

			form.addEventListener('submit', onSubmit);
		}
	}

}