const slider = {
	slider: null,
	sliderInt: -1,
	init: function () {
		var self = this;

		this.slider = document.getElementById('slider');

		if (this.slider) {
			if (+this.slider.getAttribute('data-slides') > 1) {
				this.sliderIntInit();

				[].slice.call(this.slider.getElementsByClassName('pips')[0].getElementsByTagName('a')).forEach(function (el) {
					el.addEventListener('click', function (e) {
						e.preventDefault();
						clearInterval(self.sliderInt);
						self.goToSlide(el.getAttribute('data-slide'), true);
					});
				});
			}
		}
	},
	sliderIntInit: function () {
		var self = this;

		this.sliderInt = setInterval(function () {
			var currentSlide = self.slider.querySelector('.active');

			if (currentSlide.nextElementSibling && currentSlide.nextElementSibling.getAttribute('data-slide')) {
				self.goToSlide(currentSlide.nextElementSibling.getAttribute('data-slide'));
			} else {
				self.goToSlide(currentSlide.parentNode.firstChild.getAttribute('data-slide'));
			}
		}, 10000);
	},
	goToSlide: function (i, reInitInt) {
		[].slice.call(this.slider.getElementsByClassName('active')).forEach(function (el) {
			el.classList.remove('active');
		});

		[].slice.call(this.slider.querySelectorAll('[data-slide="' + i + '"]')).forEach(function (el) {
			el.classList.add('active');
		});

		var activeColor = this.slider.querySelector('.active[data-colors]');
		activeColor.parentNode.className = 'content scheme-' + activeColor.getAttribute('data-colors');

		if (reInitInt) this.sliderIntInit();
	}
};

export default slider;