/* global mixitup */
// NOTE: MixItUp 3 is in beta and may break without warning
const events = {
	mixer: null,
	cat: '',
	season: '',
	bottomDist: 0,
	init: function () {
		var self = this;

		if (!window.isMobile) this.initBar();

		var noResults = document.getElementById('noResults');
		this.mixer = mixitup(document.getElementById('events'), {
			selectors: {
				target: 'a'
			},
			callbacks: {
				onMixStart: function () {
					noResults.classList.remove('show');
				},
				onMixEnd: function (e) {
					if (e.matching.length < 1)
						noResults.classList.add('show');
				}
			}
		});

		document.getElementById('eventCategories').addEventListener('change', function () {
			self.cat = this.value;
			self.filter();
		});

		document.getElementById('seasons').addEventListener('change', function () {
			self.season = this.value;
			self.filter();
		});

		document.getElementById('events').addEventListener('transitionend', function () {
			this.bottomDist = document.getElementById('siteFooter').offsetTop - 40 - 70;
		});
	},

	initBar: function () {
		var bar = document.getElementById('bar'),
			topDist = bar.parentNode.offsetTop,
			self = this;

		this.bottomDist = document.getElementById('siteFooter').offsetTop - 40 - 70;

		//console.log(this.bottomDist);

		window.addEventListener('scroll', function () {
			if (window.pageYOffset >= self.bottomDist) {
				bar.classList.remove('fixed');
				bar.classList.add('end');
			} else if (window.pageYOffset >= topDist) {
				bar.classList.add('fixed');
				bar.classList.remove('end');
			} else {
				bar.classList.remove('fixed');
				bar.classList.remove('end');
			}
		});
	},

	filter: function () {
		var filter = '';

		if (this.cat === '' && this.season === '') {
			filter = '*';
		} else {
			if (this.cat)
				filter += '.' + this.cat;

			if (this.season)
				filter += '.' + this.season;
		}

		this.mixer.filter(filter);
	}
};

export default events;