import {ScrollTo} from "./Helpers";

const search = {
	elem: document.getElementById('search'),
	input: document.getElementById('searchField'),
	results: {
		pages: document.getElementById('resultsPages'),
		events: document.getElementById('resultsEvents'),
		news: document.getElementById('resultsNews')
	},
	init: function () {
		var self = this;

		[].slice.call(document.querySelectorAll('a[data-search]')).forEach(function (el) {
			el.addEventListener('click', function (e) {
				e.preventDefault();
				if (window.scrollY > 0 && !window.isMobile) {
					new ScrollTo(0, function () {
						self.open();
					});
				} else {
					self.open();
				}
			});
		});

		this.elem.getElementsByClassName('close')[0].addEventListener('click', function (e) {
			e.preventDefault();
			document.body.classList.remove('search-open');
		});

		document.addEventListener('keydown', function (e) {
			if (e.keyCode === 27 && document.body.classList.contains('search-open'))
				document.body.classList.remove('search-open');
		});

		this.run();
	},
	open: function () {
		var self = this;
		document.body.classList.add('search-open');
		setTimeout(function () {
			self.input.focus();
		}, 500);
	},
	run: function () {
		var self = this,
			timeout;

		this.input.addEventListener('input', function () {
			self.elem.classList.add('working');

			if (timeout) clearTimeout(timeout);

			timeout = setTimeout(function () {
				self.search();
			}, 1000);
		});
	},
	search: function () {
		var self = this,
			q = this.input.value,
			request = new XMLHttpRequest();

		if (q === '') {
			this.populateResults({
				pages: [],
				events: [],
				news: []
			});
			return;
		}

		request.open('GET', window.baseUrl + 'search-results?q=' + q, true);
		request.onload = function () {
			var results = JSON.parse(request.responseText);

			self.populateResults(results);
		};
		request.send();
	},
	populateResults: function (results) {
		for (var key in results) {
			if (results.hasOwnProperty(key)) {
				var res = results[key],
					elems = '';

				if (res.length > 0) {
					for (var i = 0; i < res.length; i++)
						elems += '<a href="' + res[i][1] + '">' + res[i][0] + '</a>';
				} else {
					elems = '<span>No Results</span>';
				}

				this.results[key].innerHTML = elems;
			}
		}

		this.elem.classList.remove('working');
	}
};

export default search;