export function easeInOutQuad( t, b, c, d ) {
	t /= d / 2;
	if ( t < 1 ) return c / 2 * t * t + b;
	t--;
	return -c / 2 * ( t * ( t -2 ) - 1 ) + b;
}

export function serialize(form) {
	if (!form || form.nodeName !== "FORM") return '';

	var i, j, q = [];
	for (i = form.elements.length - 1; i >= 0; i = i - 1) {
		if (form.elements[i].name === "") {
			continue;
		}
		switch (form.elements[i].nodeName) {
			case 'INPUT':
				switch (form.elements[i].type) {
					case 'text':
					case 'email':
					case 'tel':
					case 'number':
					case 'hidden':
					case 'password':
					case 'button':
					case 'reset':
					case 'submit':
						q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
						break;
					case 'checkbox':
					case 'radio':
						if (form.elements[i].checked) {
							q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
						}
						break;
					case 'file':
						break;
				}
				break;
			case 'TEXTAREA':
				q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
				break;
			case 'SELECT':
				switch (form.elements[i].type) {
					case 'select-one':
						q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
						break;
					case 'select-multiple':
						for (j = form.elements[i].options.length - 1; j >= 0; j = j - 1) {
							if (form.elements[i].options[j].selected) {
								q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].options[j].value));
							}
						}
						break;
				}
				break;
			case 'BUTTON':
				switch (form.elements[i].type) {
					case 'reset':
					case 'submit':
					case 'button':
						q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
						break;
				}
				break;
		}
	}
	q.reverse();
	return q.join("&").replace(/%20/g, '+');
}

export function ScrollTo (endPos, finishedCallback) {
	var startPosition = (document.documentElement.scrollTop || document.body.scrollTop),
		endPosition = endPos || 0,
		startTime = null;

	function loop (time) {
		if ( !startTime )
			startTime = time;

		var timeSoFar = time - startTime;
		var easedPosition = easeInOutQuad(timeSoFar, startPosition, endPosition - startPosition, 1000);

		window.scrollTo(0, easedPosition);

		if( timeSoFar < 1000 )
			requestAnimationFrame(loop);
		else if (typeof finishedCallback === 'function')
			finishedCallback();
	}

	requestAnimationFrame(loop);
}

export function addMinutes(date, minutes) {
	return new Date(date.getTime() + minutes*60000);
}

export function ordinal(i) {
	var j = i % 10,
		k = i % 100;
	if (j === 1 && k !== 11) {
		return i + "st";
	}
	if (j === 2 && k !== 12) {
		return i + "nd";
	}
	if (j === 3 && k !== 13) {
		return i + "rd";
	}
	return i + "th";
}

export const monthNames = ["January", "February", "March", "April", "May", "June",
	"July", "August", "September", "October", "November", "December"
];

export const prefix = (function () {
	var styles = window.getComputedStyle(document.documentElement, ''),
		pre = (Array.prototype.slice
				.call(styles)
				.join('')
				.match(/-(moz|webkit|ms)-/) || (styles.OLink === '' && ['', 'o'])
		)[1],
		dom = ('WebKit|Moz|MS|O').match(new RegExp('(' + pre + ')', 'i'))[1];
	return {
		dom: dom,
		lowercase: pre,
		css: '-' + pre + '-',
		js: pre[0].toUpperCase() + pre.substr(1)
	};
})();

// export function makeDate(dateString) {
// 	const ua = navigator.userAgent.toLowerCase(),
// 		isFirefox = ua.indexOf('firefox') > -1;
//
// 	dateString = dateString.replace(' ', 'T');
//
// 	let date = new Date(dateString);
// 	if (!isFirefox) date = new Date(new Date(dateString).getTime() + (new Date().getTimezoneOffset() * 60 * 1000));
//
// 	return date;
// }