import message from "./message";
import {serialize} from "./Helpers";

const forms = {
	init: function () {
		[].slice.call(document.getElementsByTagName('textarea')).forEach(function (ta) {
			ta.style.height = "";
			ta.style.height = ta.scrollHeight + "px";

			ta.addEventListener('input', function () {
				this.style.height = "";
				this.style.height = this.scrollHeight + "px";
			});
		});

		[].slice.call(document.querySelectorAll('form[data-form]')).forEach(function (form) {
			var fromEmail = form.querySelector('[name="fromEmail"]');

			if (fromEmail) {
				var fakeEmail = document.createElement('input');
				fakeEmail.setAttribute('type', 'hidden');
				fakeEmail.setAttribute('name', 'message[Email]');

				form.insertBefore(fakeEmail, form.firstElementChild);

				fromEmail.addEventListener('input', function () {
					fakeEmail.value = fromEmail.value;
				});

				if (form.querySelector('[name="message"]'))
					form.querySelector('[name="message"]').setAttribute('name', 'message[body]');
			}

			form.addEventListener('submit', function (e) {
				e.preventDefault();

				form.classList.add('working');

				[].slice.call(form.getElementsByClassName('has-error')).forEach(function (el) {
					el.classList.remove('has-error');
				});

				var request = new XMLHttpRequest();
				request.open('POST', '/', true);
				request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
				request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
				request.setRequestHeader('Accept', 'application/json');
				request.onload = function () {
					var res = JSON.parse(request.responseText);
					form.classList.remove('working');

					if (res.success) {
						message.show(form.getAttribute('data-success'));
						form.reset();
					} else {
						var key;
						// console.log(res);

						if (res.errors) {
							key = (function () {
								for (var e in res.errors) return e;
							})();
							message.show(res.errors[key][0], true);
						}

						if (res.error) {
							if (Array.isArray(res.error)) {
								message.show(res.error[0], true);
							} else if (typeof res.error === 'object') {
								key = (function () {
									for (var e in res.error) return e;
								})();
								message.show(res.error[key][0], true);
							} else {
								message.show(res.error, true);
							}
						}

						if (key) {
							var input = form.querySelector('[name="' + key + '"]');

							if (key === 'message' && !input)
								input = form.querySelector('[name="message[body]"]');

							if (key === 'message' && !input)
								input = form.querySelector('[name="message"]');

							if (input)
								input.classList.add('has-error');
						}
					}
				};
				request.send(serialize(form));
			});
		});
	}
};

export default forms;