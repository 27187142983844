export default class Cart {

	constructor () {
		const qtys = document.querySelectorAll('input[name=qty]');

		[].slice.call(qtys).forEach((qty) => {
			qty.addEventListener('change', () => {
				qty.parentNode.parentNode.submit();
			});
		});
	}

}