/* globals Pikaday */
import message from "./message";
import XHR from "./XHR";
import {addMinutes, ordinal, monthNames} from "./Helpers";

export default class Event {

	constructor () {
		this.calendarElem = document.getElementById('modalCalendar');

		if (
			typeof this.calendarElem === typeof undefined ||
			document.getElementById('modalTickets') === null
		) return;

		this.selectedDateElem = document.getElementById('modalDate');
		this.slotsSelect = document.getElementById('modalSlots');
		this.selectOverlay = document.getElementById('modalSelectOverlay');
		this.submit = document.getElementById('modalSubmit') || document.createElement('div');
		this.tickets = [].slice.call(document.getElementById('modalTickets').getElementsByTagName('input'));
		this.total = document.getElementById('modalTotal');

		this.form = document.getElementById('bookingForm');
		this.allSubmit = document.getElementById('allSubmit');

		this.initModal();

		window.eventInstance = this;

		if (this.allSubmit)
			this.allSubmit.addEventListener('click', this.onAllSubmit.bind(this));
	}

	initModal () {
		let self = this;

		const overlay = document.getElementById('modalOverlay')
			, modalTrigger = document.getElementById('bookModalTrigger');

		if (modalTrigger === void 0) return;

		this.eventId = +modalTrigger.dataset.eventId;
		this.duration = +modalTrigger.dataset.duration;

		overlay.addEventListener('click', function (e) {
			if (e.target === this) {
				document.body.classList.remove('modal-open');

				setTimeout(() => {
					self.onClose();
				}, 500);
			}
		});

		modalTrigger.addEventListener('click', (e) => {
			e.preventDefault();
			this.onOpen(modalTrigger);
		});
	}

	onOpen (button) {
		button.classList.add('working');

		XHR.action('b/bookings/availability', {
			eventId: this.eventId,
			groupBy: "day",
		}).then((data) => {
			this.slots = data;
			this.onGet();
			button.classList.remove('working');
		}).catch((err) => {
			message.show(err, true);
			button.classList.add('working');
		});
	}

	static zeroPad (v) {
		return (v < 10 ? '0' : '') + v;
	}

	onGet () {
		const dates = Object.keys(this.slots);

		const today = new Date()
			, minDate = new Date(dates[0].replace(" ", "T") + "Z")
			, maxDate = new Date([...dates].pop().replace(" ", "T") + "Z");

		this.picker = new Pikaday({
			field: document.createElement('input'),
			container: this.calendarElem,
			bound: false,
			defaultDate: minDate < today ? today : minDate,
			minDate: minDate < today ? today : minDate,
			maxDate: maxDate,
			firstDay: 1,
			showDaysInNextAndPreviousMonths: true,
			disableDayFn: (date) => {
				let ts = date.getFullYear() + '-' + Event.zeroPad(date.getMonth() + 1) + '-' + Event.zeroPad(date.getDate());

				for (let i = 0, l = dates.length; i < l; ++i)
					if (dates[i].indexOf(ts) > -1 && this.slots[dates[i]] > 0)
						return false;

				return true;
			},
			onSelect: date => {
				date.setHours(1);
				date.setMinutes(0);
				date.setSeconds(0);

				const end = new Date(date);
				end.setDate(date.getDate() + 1);

				this.selectOverlay.firstElementChild.textContent = "Loading...";
				this.selectOverlay.classList.remove('hide');
				this.submit.disabled = true;

				XHR.action('b/bookings/availability', {
					eventId: this.eventId,
					start: date.toISOString(),
					end: end.toISOString(),
					groupBy: "minute",
				}).then(data => {

					const timezoneOffset = Math.abs(date.getTimezoneOffset() / 60);
					date.setHours(timezoneOffset, 0, 0, 0);

					this.selectedDateElem.textContent = `${ordinal(date.getDate())} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;

					while (this.slotsSelect.firstElementChild)
						this.slotsSelect.removeChild(this.slotsSelect.firstElementChild);

					Object.keys(data).forEach(key => {
						const availability = data[key];

						let opt = document.createElement('option'),
							d = new Date(key.replace(" ", "T") + "Z");

						const dd = addMinutes(d, this.duration);

						opt.value = d;

						if (window.location.href.indexOf('hop-farm-entry') > -1) {
							opt.textContent = `${d.getHours()}:${String("0" + d.getMinutes()).slice(-2)}`;
						} else {
							opt.textContent = `${d.getHours()}:${String("0" + d.getMinutes()).slice(-2)} - ${dd.getHours()}:${String("0" + dd.getMinutes()).slice(-2)}`;
						}

						if (availability <= 0) {
							opt.dataset.ticketsLeft = -1;
							opt.disabled = true;
							opt.textContent = opt.textContent + " (sold out)";
						} else {
							opt.dataset.ticketsLeft = availability;
							// opt.textContent = opt.textContent + ` (${availability} slot${availability !== 1 ? 's' : ''} left)`;
						}

						this.slotsSelect.appendChild(opt);
					});

					this.onSelectDate();

					setTimeout(() => {
						this.selectOverlay.firstElementChild.textContent = "Please select a date!";
					}, 300);

				}).catch(err => {
					message.show(err, true);
				});

				// const timezoneOffset = Math.abs(date.getTimezoneOffset() / 60);
				// date.setHours(timezoneOffset,0,0,0);
				//
				// this.selectedDateElem.textContent = `${ordinal(date.getDate())} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
				//
				// let slots = this.slots.byDay[date.getTime() / 1000];
				//
				// if (!slots) slots = [];
				//
				// while (this.slotsSelect.firstElementChild)
				// 	this.slotsSelect.removeChild(this.slotsSelect.firstElementChild);
				//
				// for (var key in slots) {
				// 	if (!slots.hasOwnProperty(key)) continue;
				// 	let slot = slots[key];
				//
				// 	if (slot.overnight) continue;
				//
				// 	let opt = document.createElement('option'),
				// 		d = new Date(slot.slot.date.replace(' ', 'T'));
				//
				// 	let ua = navigator.userAgent.toLowerCase();
				// 	if (timezoneOffset > 0 &&
				// 		ua.indexOf('firefox') === -1 &&
				// 		ua.indexOf('msie ') === -1 &&
				// 		ua.indexOf('trident/') === -1 &&
				// 		ua.indexOf('edge/') === -1)
				// 		d.setHours(d.getHours() - timezoneOffset);
				//
				// 	let dd = addMinutes(d, this.slots.duration);
				//
				// 	opt.value = slot.slot.date;
				// 	opt.textContent = `${d.getHours()}:${String("0" + d.getMinutes()).slice(-2)} - ${dd.getHours()}:${String("0" + dd.getMinutes()).slice(-2)}`;
				//
				// 	if (this.slots.maxTicketsPer > 0 &&
				// 		slot.ticketsAvailable > -1 && !slot.unlimited) {
				// 		opt.textContent = opt.textContent + ` (${slot.ticketsAvailable} ticket${slot.ticketsAvailable !== 1 ? 's' : ''} left)`;
				// 		opt.dataset.ticketsLeft = +slot.ticketsAvailable;
				// 	} else {
				// 		opt.dataset.ticketsLeft = -1;
				// 	}
				//
				// 	if (slot.availability !== "available" ||
				// 		(this.slots.maxTicketsPer > 0 && slot.ticketsAvailable < 1 && !this.slots.unlimited)) {
				// 		opt.disabled = true;
				// 		opt.textContent = opt.textContent + ` (sold out)`;
				// 	}
				//
				// 	this.slotsSelect.appendChild(opt);
				// }
				//
				// this.onSelectDate();
			}
		});

		this.form.addEventListener('submit', this.onFormSubmit);

		document.body.classList.add('modal-open');
	}

	onSelectDate () {
		this.selectOverlay.classList.add('hide');
		this.submit.disabled = false;

		var tqCheck = (totalQty) => {
			if (typeof totalQty === typeof undefined) {
				totalQty = 0;

				this.tickets.forEach((t) => {
					totalQty += +t.value;
				});
			}

			this.submit.disabled = false;

			if (this.slots.maxTicketsPer > 0 && !this.slots.unlimited)
				if (totalQty > this.slotsSelect.options[this.slotsSelect.selectedIndex].dataset.ticketsLeft)
					this.submit.disabled = true;
		};

		tqCheck();

		this.slotsSelect.addEventListener('change', () => {
			tqCheck();
		});

		this.tickets.forEach((ticket) => {
			ticket.addEventListener('input', () => {
				var total = 0,
					totalQty = 0,
					saleTotal = 0;

				this.tickets.forEach((t) => {
					total += +t.dataset.price * +t.value;
					totalQty += +t.value;
					if (t.dataset.sale) saleTotal += +t.dataset.sale * +t.value;
				});

				while (this.total.firstChild)
					this.total.removeChild(this.total.firstChild);

				if (saleTotal > 0) {
					let totalNode = document.createTextNode(total.toLocaleString('en-GB', {style: 'currency', currency: 'GBP'})),
						saleNode = document.createElement('span');
					saleNode.textContent = ' save ' + saleTotal.toLocaleString('en-GB', {style: 'currency', currency: 'GBP'});
					this.total.appendChild(totalNode);
					this.total.appendChild(saleNode);
				} else {
					this.total.textContent = total.toLocaleString('en-GB', {style: 'currency', currency: 'GBP'});
				}

				tqCheck(totalQty);
			});
		});
	}

	onClose () {
		this.picker.destroy();

		this.selectedDateElem.innerHTML = '&nbsp;';

		while (this.slotsSelect.firstElementChild)
			this.slotsSelect.removeChild(this.slotsSelect.firstElementChild);

		this.selectOverlay.classList.remove('hide');
		this.submit.disabled = true;

		this.tickets.forEach((ticket) => {
			ticket.value = ticket.min;
		});

		this.total.textContent = this.total.dataset.default;

		this.form.removeEventListener('submit', this.onFormSubmit);
	}

	onFormSubmit (e) {
		e.preventDefault();

		const self = window.eventInstance
			, items = [];

		self.tickets.forEach((ticket) => {
			if (+ticket.value <= 0)
				return;

			items.push({
				id: +ticket.dataset.id,
				qty: +ticket.value,
				options: {
					ticketId: +ticket.dataset.ticket,
					ticketDate: (new Date(self.slotsSelect.value)).toISOString(),
				}
			});
		});

		this.classList.add('working');

		XHR.action('commerce/cart/update-cart', {
			purchasables: items
		}).then((res) => {
			if (res.hasOwnProperty('error')) {
				message.show(res.error, true);
			} else {
				window.location.href = window.baseUrl + 'cart';
				// message.show('Booking added to cart!');
			}
			this.classList.remove('working');
		}).catch((err) => {
			message.show(err, true);
			this.classList.remove('working');
		});
	}

	onAllSubmit (e) {
		e.preventDefault();

		const items = [];

		this.tickets.forEach((ticket) => {
			if (+ticket.value <= 0)
				return;

			items.push({
				id: +ticket.dataset.id,
				qty: +ticket.value,
				options: {
					ticketId: +ticket.dataset.ticket,
					ticketDate: (new Date(this.slotsSelect.value)).toISOString(),
				}
			});
		});

		if (items.length === 0) {
			message.show('You must select some tickets!', true);
			return;
		}

		this.form.classList.add('working');

		XHR.action('commerce/cart/update-cart', {
			purchasables: items
		}).then(async res => {
			if (res.hasOwnProperty('error')) {
				message.show(res.error, true);
			} else {
				try {
					await window.__onBookClick();
					window.location.href = window.baseUrl + 'cart';
				} catch (e) {
					message.show(e.message, true);
				}
			}
			this.form.classList.remove('working');
		}).catch((err) => {
			message.show(err, true);
			this.form.classList.remove('working');
		});
	}

}