import message from "./message";
import {serialize} from "./Helpers";

const account = {
	init: function () {
		if (document.getElementById('avatarImage'))
			this.avatarModal();

		this.forms();
	},

	avatarModal: function () {
		var input = document.getElementById('avatarInput'),
			image = document.getElementById('avatarImage'),
			overlay = document.getElementById('modalOverlay');

		image.addEventListener('click', function () {
			document.body.classList.add('modal-open');
		});

		overlay.addEventListener('click', function (e) {
			if (e.target === this)
				document.body.classList.remove('modal-open');
		});

		[].slice.call(overlay.getElementsByTagName('img')).forEach(function (img) {
			img.addEventListener('click', function () {
				if (overlay.getElementsByClassName('active').length)
					overlay.getElementsByClassName('active')[0].classList.remove('active');
				img.classList.add('active');

				var n = img.getAttribute('data-avatar');
				input.value = n;
				image.src = window.baseUrl + 'assets/imgs/avatars/' + n + '.svg';

				document.body.classList.remove('modal-open');
			});
		});
	},

	forms: function () {
		[].slice.call(document.forms).forEach(function (form) {
			const onSubmit = (e) => {
				e.preventDefault();
				form.classList.add('working');

				const request = new XMLHttpRequest();
				request.open('POST', '/', true);
				request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
				request.setRequestHeader("Accept", "application/json");
				request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
				request.onload = function () {
					const res = JSON.parse(request.responseText);
					form.classList.remove('working');

					if (res.success) {
						message.show(form.getAttribute('data-saved') || 'Saved!');

						if (form.elements.redirect) {
							setTimeout(() => {
								window.location = window.baseUrl + form.elements.redirect.value;
							}, 1000);
						}
					} else {
						const key = (function () {
							for (let e in res.errors) return e;
						})();
						message.show(res.errors[key][0], true);
					}
				};
				request.send(serialize(form));
			};

			if (form.dataset.hasOwnProperty('confirm')) {
				form.addEventListener('submit', (e) => {
					e.preventDefault();
					if (confirm(form.dataset.confirm || 'Are you sure?')) {
						onSubmit(e);
					}
				});
			} else {
				form.addEventListener('submit', onSubmit);
			}
		});
	}
};

export default account;