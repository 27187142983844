import message from "./message";
import {serialize} from "./Helpers";

const login = {
	main: document.getElementById('loginMain'),
	cont: document.getElementById('loginContainer'),
	init: function () {
		var self = this;

		window.addEventListener('hashchange', function () {
			self.changeView();
		});
		this.changeView();

		[].slice.call(document.forms).forEach(function (el) {
			el.addEventListener('submit', function (e) {
				e.preventDefault();

				el.classList.add('working');

				var request = new XMLHttpRequest();
				request.open('POST', '/', true);
				request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
				request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
				request.onload = function () {
					var res = JSON.parse(request.responseText);
					el.classList.remove('working');

					if (res.success) {
						window.location = window.baseUrl + 'account';
					} else {
						if (res.errors) {
							var key = (function () {
								for (var e in res.errors) return e;
							})();
							message.show(res.errors[key][0], true);
						}

						if (res.error) {
							message.show(res.error, true);
						}
					}
				};
				request.send(serialize(el));
			});
		});

		var fullName = document.getElementById('fullName'),
			firstName = document.getElementById('firstName'),
			lastName = document.getElementById('lastName');
		fullName.addEventListener('input', function () {
			var name = this.value.split(' ');
			firstName.value = name[0];
			if (name[1]) lastName.value = name[1];
		});
	},
	changeView: function () {
		var hash = window.location.hash.substr(1),
			cls = 'go-open';


		switch (hash) {
			case 'create':
				cls = 'reg-open';
				break;
			case 'login':
				cls = 'log-open';
				break;
		}

		this.cont.className = 'login--container ' + cls;
	}
};

export default login;