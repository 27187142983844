const index = {
	init: function () {
		if (window.innerWidth > 992) this.msnryInit();
	},

	msnry: null,
	msnryInit: function () {
		import('masonry-layout').then(({ default: Masonry }) => {
			this.msnry = new Masonry(document.getElementById('newGrid'), {
				itemSelector: 'a'
			});
		});
	}
};

export default index;