import {easeInOutQuad} from "./Helpers";
import slider from "./slider";
import index from "./index";

export default function mobile () {
	// Swipe
	var xDown = null,
		yDown = null;

	document.getElementById('toggleNav').addEventListener('click', function (e) {
		e.preventDefault();
		document.body.classList.add('nav-open');
		if (index.sliderInt) clearInterval(slider.sliderInt);
	});

	document.addEventListener('touchstart', function (e) {
		var target = e.target;
		while (target) {
			if (target.classList.contains('no-swipe'))
				return;

			if (target.offsetParent)
				target = target.offsetParent;
			else
				target = false;
		}

		xDown = e.touches[0].clientX;
		yDown = e.touches[0].clientY;
	}, false);

	document.addEventListener('touchmove', function (e) {
		if ( ! xDown || ! yDown ) {
			return;
		}

		var xUp = e.touches[0].clientX;
		var yUp = e.touches[0].clientY;

		var xDiff = xDown - xUp;
		var yDiff = yDown - yUp;

		if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
			if ( xDiff > 0 ) {
				document.body.classList.add('nav-open');
				if (slider.sliderInt) clearInterval(slider.sliderInt);
			} else {
				document.body.classList.remove('nav-open');
				if (slider.slider) slider.sliderIntInit();
			}
		}

		xDown = null;
		yDown = null;
	}, false);

	var swipe = document.getElementById('closeSwipe');

	if (localStorage.getItem('swipe')) {
		swipe.parentNode.remove();
	} else {
		swipe.addEventListener('click', function (e) {
			e.preventDefault();
			swipe.parentNode.classList.add('hide');
			localStorage.setItem('swipe', true);
			swipe.parentNode.addEventListener('transitionend', function () {
				swipe.parentNode.remove();
			});
		});
	}

	// Menu // http://tympanus.net/codrops/2015/11/17/multi-level-menu/
	var support = { animations : true },
		animEndEventName = 'animationend',
		onEndAnimation = function( el, callback ) {
			var onEndCallbackFn = function( ev ) {
				if( support.animations ) {
					if( ev.target !== this ) return;
					this.removeEventListener( animEndEventName, onEndCallbackFn );
				}
				if( callback && typeof callback === 'function' ) { callback.call(); }
			};
			if( support.animations ) {
				el.addEventListener( animEndEventName, onEndCallbackFn );
			}
			else {
				onEndCallbackFn();
			}
		};

	function extend( a, b ) {
		for( var key in b ) {
			if( b.hasOwnProperty( key ) ) {
				a[key] = b[key];
			}
		}
		return a;
	}

	function MLMenu(el, options) {
		this.el = el;
		this.options = extend( {}, this.options );
		extend( this.options, options );

		// the menus (´s)
		this.menus = [].slice.call(this.el.getElementsByTagName('div'));
		// index of current menu
		this.current = 0;

		this._init();
	}

	MLMenu.prototype.options = {
		// show breadcrumbs
		breadcrumbsCtrl : true,
		// initial breadcrumb text
		initialBreadcrumb : 'all',
		// show back button
		backCtrl : true,
		// delay between each menu item sliding animation
		itemsDelayInterval : 60,
		// direction
		direction : 'r2l',
		// callback: item that doesn´t have a submenu gets clicked
		// onItemClick([event], [inner HTML of the clicked item])
		onItemClick : function(/*ev, itemName*/) { return false; }
	};

	MLMenu.prototype._init = function() {
		// iterate the existing menus and create an array of menus, more specifically an array of objects where each one holds the info of each menu element and its menu items
		this.menusArr = [];
		var self = this;
		this.menus.forEach(function(menuEl, pos) {
			var menu = {
				menuEl : menuEl,
				menuItems : [].slice.call(menuEl.getElementsByTagName('a'))
			};
			menu.menuItems.forEach(function (el) {
				if (el.hasAttribute('data-submenu')) {
					el.appendChild(document.createElement('span'));
				}
			});
			self.menusArr.push(menu);

			// set current menu class
			if( pos === self.current ) {
				menuEl.classList.add('menu--current');
			}
		});

		// create back button
		if( this.options.backCtrl ) {
			this.backCtrl = document.createElement('button');
			this.backCtrl.className = 'menu__back menu__back--hidden';
			this.backCtrl.setAttribute('aria-label', 'Go back');
			this.backCtrl.innerHTML = '';
			this.el.insertBefore(this.backCtrl, this.el.firstChild);
		}

		// create breadcrumbs
		if( self.options.breadcrumbsCtrl ) {
			this.breadcrumbsCtrl = document.createElement('nav');
			this.breadcrumbsCtrl.className = 'breadcrumbs';
			this.el.insertBefore(this.breadcrumbsCtrl, this.el.firstChild);
			// add initial breadcrumb
			this._addBreadcrumb(0);
		}

		// event binding
		this._initEvents();
	};

	MLMenu.prototype._initEvents = function() {
		var self = this;

		for(var i = 0, len = this.menusArr.length; i < len; ++i) {
			this.menusArr[i].menuItems.forEach(function(item, pos) { // jshint ignore:line
				item.addEventListener('click', function (ev) {
					var submenu = ev.target.parentNode.getAttribute('data-submenu'),
						itemName = ev.target.parentNode.textContent,
						subMenuEl = self.el.querySelector('[data-menu="' + submenu + '"]');

					// check if there's a sub menu for this item
					if( submenu && subMenuEl ) {
						ev.preventDefault();
						// open it
						self._openSubMenu(subMenuEl, pos, itemName);
					}
				});
			});

			// Scroll
			this.menusArr[i].menuEl.addEventListener('scroll', function (e) { // jshint ignore:line
				if (e.target.classList.contains('menu--current')) {
					if (e.target.scrollTop > 0)
						self.breadcrumbsCtrl.classList.add('scrolled');
					else
						self.breadcrumbsCtrl.classList.remove('scrolled');
				}
			});
		}

		// back navigation
		if( this.options.backCtrl ) {
			this.backCtrl.addEventListener('click', function() {
				self._back();
			});
		}
	};

	MLMenu.prototype._openSubMenu = function(subMenuEl, clickPosition, subMenuName) {
		if( this.isAnimating ) {
			return false;
		}
		this.isAnimating = true;

		// save "parent" menu index for back navigation
		this.menusArr[this.menus.indexOf(subMenuEl)].backIdx = this.current;
		// save "parent" menu´s name
		this.menusArr[this.menus.indexOf(subMenuEl)].name = subMenuName;
		// current menu slides out
		this._menuOut(clickPosition);
		// next menu (submenu) slides in
		this._menuIn(subMenuEl, clickPosition);
	};

	MLMenu.prototype._back = function() {
		if( this.isAnimating ) {
			return false;
		}
		this.isAnimating = true;

		// current menu slides out
		this._menuOut();
		// next menu (previous menu) slides in
		var backMenu = this.menusArr[this.menusArr[this.current].backIdx].menuEl;
		this._menuIn(backMenu);

		// remove last breadcrumb
		if( this.options.breadcrumbsCtrl ) {
			this.breadcrumbsCtrl.removeChild(this.breadcrumbsCtrl.lastElementChild);
		}
	};

	MLMenu.prototype._menuOut = function(clickPosition) {
		// the current menu
		var self = this,
			delay = 0,
			currentMenu = this.menusArr[this.current].menuEl,
			isBackNavigation = typeof clickPosition === "undefined";

		if (currentMenu.scrollTop > 0) {
			delay = 200;

			var startPosition = currentMenu.scrollTop,
				endPosition =  0,
				startTime = null;

			function loop (time) { // eslint-disable-line no-inner-declarations
				if ( !startTime )
					startTime = time;

				var timeSoFar = time - startTime;

				currentMenu.scrollTop = easeInOutQuad(timeSoFar, startPosition, endPosition - startPosition, delay);

				if( timeSoFar < delay )
					requestAnimationFrame(loop);
			}

			requestAnimationFrame(loop);
		}

		setTimeout(function () {
			self.breadcrumbsCtrl.classList.remove('scrolled');

			// slide out current menu items - first, set the delays for the items
			self.menusArr[self.current].menuItems.forEach(function(item, pos) {
				item.style.WebkitAnimationDelay = item.style.animationDelay = isBackNavigation ? parseInt(pos * self.options.itemsDelayInterval) + 'ms' : parseInt(Math.abs(clickPosition - pos) * self.options.itemsDelayInterval) + 'ms';
			});
			// animation class
			if( self.options.direction === 'r2l' ) {
				currentMenu.classList.add(!isBackNavigation ? 'animate-outToLeft' : 'animate-outToRight');
			}
			else {
				currentMenu.classList.add(isBackNavigation ? 'animate-outToLeft' : 'animate-outToRight');
			}
		}, delay);

		return delay;
	};

	MLMenu.prototype._menuIn = function(nextMenuEl, clickPosition) {
		var self = this,
			// the current menu
			currentMenu = this.menusArr[this.current].menuEl,
			isBackNavigation = typeof clickPosition === "undefined",
			// index of the nextMenuEl
			nextMenuIdx = this.menus.indexOf(nextMenuEl),

			nextMenuItems = this.menusArr[nextMenuIdx].menuItems,
			nextMenuItemsTotal = nextMenuItems.length;

		// slide in next menu items - first, set the delays for the items
		nextMenuItems.forEach(function(item, pos) {
			item.style.WebkitAnimationDelay = item.style.animationDelay = isBackNavigation ? parseInt(pos * self.options.itemsDelayInterval) + 'ms' : parseInt(Math.abs(clickPosition - pos) * self.options.itemsDelayInterval) + 'ms';

			// we need to reset the classes once the last item animates in
			// the "last item" is the farthest from the clicked item
			// let's calculate the index of the farthest item
			var farthestIdx = clickPosition <= nextMenuItemsTotal/2 || isBackNavigation ? nextMenuItemsTotal - 1 : 0;

			if( pos === farthestIdx ) {
				onEndAnimation(item, function() {
					// reset classes
					if( self.options.direction === 'r2l' ) {
						currentMenu.classList.remove(!isBackNavigation ? 'animate-outToLeft' : 'animate-outToRight');
						nextMenuEl.classList.remove(!isBackNavigation ? 'animate-inFromRight' : 'animate-inFromLeft');
					}
					else {
						currentMenu.classList.remove(isBackNavigation ? 'animate-outToLeft' : 'animate-outToRight');
						nextMenuEl.classList.remove(isBackNavigation ? 'animate-inFromRight' : 'animate-inFromLeft');
					}
					currentMenu.classList.remove('menu--current');
					nextMenuEl.classList.add('menu--current');

					//reset current
					self.current = nextMenuIdx;

					// control back button and breadcrumbs navigation elements
					if( !isBackNavigation ) {
						// show back button
						if( self.options.backCtrl ) {
							self.backCtrl.classList.remove('menu__back--hidden');
						}

						// add breadcrumb
						self._addBreadcrumb(nextMenuIdx);
					}
					else if( self.current === 0 && self.options.backCtrl ) {
						// hide back button
						self.backCtrl.classList.add('menu__back--hidden');
					}

					// we can navigate again..
					self.isAnimating = false;
				});
			}
		});

		// animation class
		if( this.options.direction === 'r2l' ) {
			nextMenuEl.classList.add(!isBackNavigation ? 'animate-inFromRight' : 'animate-inFromLeft');
		}
		else {
			nextMenuEl.classList.add(isBackNavigation ? 'animate-inFromRight' : 'animate-inFromLeft');
		}
	};

	MLMenu.prototype._addBreadcrumb = function(idx) {
		if( !this.options.breadcrumbsCtrl ) {
			return false;
		}

		var bc = document.createElement('a');
		bc.innerHTML = idx ? this.menusArr[idx].name : this.options.initialBreadcrumb;
		this.breadcrumbsCtrl.appendChild(bc);

		var self = this;
		bc.addEventListener('click', function(ev) {
			ev.preventDefault();

			// do nothing if this breadcrumb is the last one in the list of breadcrumbs
			if( !bc.nextSibling || self.isAnimating ) {
				return false;
			}
			self.isAnimating = true;

			// current menu slides out
			var delay = self._menuOut();
			// next menu slides in
			setTimeout(function () {
				var nextMenu = self.menusArr[idx].menuEl;
				self._menuIn(nextMenu);
			}, delay);

			// remove breadcrumbs that are ahead
			var siblingNode;
			while (siblingNode = bc.nextSibling) { // eslint-disable-line no-cond-assign
				self.breadcrumbsCtrl.removeChild(siblingNode);
			}
		});
	};

	MLMenu.prototype.openMenu = function (selector) {
		var self = this,
			toOpen = [];
		selector = selector.split('-');

		for (var i = selector.length - 1; i > -1; i--) {
			var s = (i > 0 ? selector[0] + '-' : '') + selector[i];
			if (this.el.querySelector('[data-menu="' + s + '"]') !== null) {
				toOpen.push({
					el: this.el.querySelector('[data-menu="' + s + '"]'),
					name: this.el.querySelector('[data-submenu="' + s + '"]').textContent
				});
			}
		}

		toOpen.reverse();

		if (toOpen.length > 1) {
			self._openSubMenu(toOpen[0].el, 0, toOpen[0].name);

			setTimeout(function () {
				self._openSubMenu(toOpen[1].el, 0, toOpen[1].name);
			}, 1000);
		}
	};

	window.menu = new MLMenu(document.getElementById('nav'), {
		backCtrl : false
	});

	// To open a menu via programatically...
	if (window.n) window.menu.openMenu(window.n);
}