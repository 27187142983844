const message = {
	message: document.getElementById('message'),
	messageTimer: undefined,
	init: function () {
		var self = this;

		this.message.addEventListener('click', function () {
			clearTimeout(self.messageTimer);
			self.message.classList.remove('open');
		});
	},
	show: function (msg, isError) {
		var self = this,
			timeout = 0;

		if (this.message.classList.contains('open')) {
			this.message.classList.remove('open');
			timeout = 500;
			clearTimeout(this.messageTimer);
		}

		setTimeout(function () {
			self.message.textContent = msg;
			if (isError) self.message.classList.add('error');
			else self.message.classList.remove('error');
			self.message.classList.add('open');

			self.messageTimer = setTimeout(function () {
				self.message.classList.remove('open');
			}, 7000);
		}, timeout);
	}
};

window.message = message;
export default message;